import { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import Filters from '../../../../components/Filters/Filters';
import './Detail.scss';
import './Summary.scss';
import axios from '../../../../utils/axios';
import { WSpinnerLarge } from '../../../../components/WLoader/WSpinner';
import { areObjectsEqual, formatNumber } from '../../../../utils/utils';

// const data = [
//   {
//     placement: '800841-412810\n1.0m-107820.0',
//     region: 'Cataluna',
//     zoneType: 'SU',
//     specificActionType: 'NewTechFreq_OUTDOOR\nR//5G_3500-TDD',
//     actionTechnology: '5G-3500-TDD',
//     revenueGenerated: '126,483.3',
//     actionCost: '47,545.0',
//     actionROI: '2.7',
//     actionOrder: '0',
//   },
//   {
//     placement: '800841-412810\n1.0m-107820.0',
//     region: 'Galicia',
//     zoneType: 'SU',
//     specificActionType: 'NewTechFreq_OUTDOOR\nR//5G_3500-TDD',
//     actionTechnology: '5G-3500-TDD',
//     revenueGenerated: '126,483.3',
//     actionCost: '47,545.0',
//     actionROI: '2.7',
//     actionOrder: '1',
//   },
//   // Add the rest of your data here
// ];

// const filterData = {
//   color: { type: 'select', values: ['Red', 'Green', 'Blue'] },
//   size: { type: 'select', values: ['Small', 'Medium', 'Large'] },
//   price: { type: 'range', values: [0, 100] }, // Range from 0 to 100
// };

const Detail = ({ scenarioID }) => {
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [filters, setFilters] = useState({}); //filters to be sent to backend
  const [loading, setLoading] = useState(true);

  const handleFilterChange = (key, type, selectedValues) => {
    if (type === 'range') {
      console.log('selectedValues', selectedValues);
    }
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: {
        type,
        values: selectedValues,
      },
    }));
    // console.log('filters', filters);
  };

  useEffect(() => {
    setLoading(true);
    const url = '/api/charts/detail/';
    axios
      .post(url, filters, {
        headers: {
          scenario_id: `scenario_${scenarioID}`,
        },
      })
      .then((response) => {
        setData(response.data.data);
        setFilterData(response.data.filters);
        const initialFilters = {};
        Object.keys(response.data.filters).forEach((key) => {
          const type = response.data.filters[key].type;
          const values = response.data.filters[key].values;
          initialFilters[key] = { type, values };
        });
        setFilters(initialFilters);
        setLoading(false);
      });
  }, [scenarioID]);

  const applyFilters = () => {
    setLoading(true);
    const url = '/api/charts/detail/';
    const filtersBody = areObjectsEqual(filters, filterData) ? {} : filters;
    axios
      .post(url, filtersBody, {
        headers: {
          scenario_id: `scenario_${scenarioID}`,
        },
      })
      .then((response) => {
        setData(response.data.data);
        // setFilterData(response.data.filters);
        setLoading(false);
      });
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, 'data.xlsx');
  };

  // useEffect(() => {
  //   applyFilters();
  // }, [filters]);

  return (
    <div className="container1">
      {loading ? (
        <div className="page-spinner">
          <WSpinnerLarge />
        </div>
      ) : (
        <>
          <div style={{ flex: 9, marginLeft: '20px', paddingTop: '5px' }}>
            <Filters
              data={filterData}
              handleFilterChange={handleFilterChange}
              filters={filters}
              applyFilters={applyFilters}
            />
          </div>
          <div style={{ flex: 2 }}>
            <button type="button" className="WButton secondary mini" style={{ margin: 0 }} onClick={exportToExcel}>
              Download As Excel
            </button>
            <div className="table-container" style={{ width: '69vw' }}>
              <table>
                <thead>
                  <tr>
                    <th>Placement</th>
                    <th>Region</th>
                    <th>Zone Type</th>
                    <th>Specific Action Type</th>
                    <th>Action Technology</th>
                    <th>Revenue Generated</th>
                    <th>Action Cost</th>
                    <th>Action ROI</th>
                    <th>Action Order</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((row, index) => (
                    <tr key={index}>
                      <td>{row.placement}</td>
                      <td>{row.region}</td>
                      <td>{row.zone_type}</td>
                      <td>{row.specific_action_type}</td>
                      <td>{row.action_technology}</td>
                      <td>{formatNumber(row.revenue_generated)}</td>
                      <td>{formatNumber(row.action_cost)}</td>
                      <td>{formatNumber(row.action_roi)}</td>
                      <td>{row.action_order}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Detail;
