/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies
import { Form, Formik, Field, useFormikContext, useField } from 'formik';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import * as Yup from 'yup';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useContext, useEffect } from 'react';
import { isEmpty, isEqual } from 'lodash';

// local dependencies
import './DatesAndParametersInput.scss';
import WText from '../../../../../../components/WText/Wtext';
import SubPhaseHeader from '../../../../../../components/SubPhaseHeader/SubPhaseHeader';
import { PHASE_STATUSES, STATUSES, SUBPHASE_STATUSES } from '../../../../../../constants/data';
import { getScenario, updateScenarioInput } from '../../../../../../redux/actions';
import { updateScenarioContext } from '../../../AutoSubmit';
import { numericOnlyCheck, rangeCheck } from '../../../../../../utils/utils';
import ScenarioContext from '../../../../ScenarioContext';

const TargetMonthField = (props) => {
  const {
    values: { endMonth },
    touched,
    setFieldValue,
  } = useFormikContext();
  const [field, meta] = useField(props);

  useEffect(() => {
    // set the value of targetMonth, based on endMonth
    if (endMonth.trim() !== '' && touched.endMonth) {
      setFieldValue(props.name, endMonth);
    }
  }, [endMonth, touched.endMonth, setFieldValue, props.name]);

  return (
    <>
      <input {...props} {...field} />
      {!!meta.touched && !!meta.error && <div>{meta.error}</div>}
    </>
  );
};

const DatesAndParametersInput = ({ phase, sectionData, user, disabled, monthDispatch }) => {
  // dispatch
  const dispatch = useDispatch();

  // context
  const scenarioContext = useContext(ScenarioContext);

  // date obj for validation
  // const dateObj = new Date();

  // handle validate date
  const validateDate = (value) => {
    let error;
    if (value?.length === 0) {
      error = 'is required';
    } else if (!(moment(value, 'YYYY/MM').format('YYYY/MM') === value)) {
      error = 'is not a valid date ';
    }
    return error;
  };

  // formik init
  const initialValues = {
    startMonth: sectionData?.value?.startMonth || '',
    endMonth: sectionData?.value?.endMonth || '',
    targetMonth: sectionData?.value?.targetMonth || '',
    qualityConsistencyThreshold: sectionData?.value?.qualityConsistencyThreshold || '0.6',
    coverageQualityThreshold: sectionData?.value?.coverageQualityThreshold || '0.8',
    trafficTailSitesNonConsidered: sectionData?.value?.trafficTailSitesNonConsidered || '0.1',
    minimumMicrosegmentSize: sectionData?.value?.minimumMicrosegmentSize || '5000',
    userName: user?.name || '',
    userRole: user?.role.name || '',
    percentageOfClients: sectionData?.value?.percentageOfClients || '1',
    kmeasnModelScenarioId: sectionData?.value?.kmeasnModelScenarioId || '',
  };

  const previousThanDates = (maxDate, currentDate) => {
    const maxMomentDate = moment(maxDate);
    const maxCurrentDate = moment(currentDate);
    if (!maxMomentDate) return false;
    if (!maxCurrentDate.isValid()) return true;
    return maxCurrentDate.endOf('month') < maxMomentDate.endOf('month');
    // const validDates = limitDates.map((limitDate) => moment(limitDate)).filter((limitDate) => limitDate.toISOString());
    // const minDate = moment.min([...validDates]);
    // const formatDate = moment(value);
    // if (!minDate.isValid() || !formatDate.isValid()) return true;
    // return formatDate && minDate && formatDate.endOf('month') < minDate.endOf('month');
  };

  const afterThanDates = (limitDates, value) => {
    const validDates = limitDates.map((limitDate) => moment(limitDate)).filter((limitDate) => limitDate.toISOString());
    const minDate = moment.min([...validDates]);
    const formatDate = moment(value);
    if (!minDate.isValid() || !formatDate.isValid()) return true;
    return formatDate && minDate && formatDate.endOf('month') > minDate.endOf('month');
  };

  const betweenDates = (limitDates, value) => {
    const validDates = limitDates.map((limitDate) => moment(limitDate)).filter((limitDate) => limitDate.toISOString());
    const minDate = moment.min([...validDates]);
    const maxDate = moment.max([...validDates]);
    const formatDate = moment(value);
    if (!minDate.isValid() || !formatDate.isValid() || !maxDate.isValid()) return true;
    return (
      formatDate &&
      minDate &&
      maxDate &&
      formatDate.endOf('month') >= minDate.endOf('month') &&
      formatDate.endOf('month') <= maxDate.endOf('month')
    );
  };

  const validationSchema = Yup.object().shape({
    startMonth: Yup.string().test(
      'test_start_month',
      // `has to be a date before ${dateObj.getUTCFullYear()}/${(dateObj.getUTCMonth() + 1).toString().padStart(2, '0')}`,
      `has to be a date before end month`,
      (value, ctx) => previousThanDates(ctx.parent.endMonth, value)
    ),
    endMonth: Yup.string().test('test_end_month', 'has to be a date after start month', (value, ctx) =>
      afterThanDates([ctx.parent.startMonth], value)
    ),
    targetMonth: Yup.string().test('test_target_month', 'has to be a date between start & end month', (value, ctx) =>
      betweenDates([ctx.parent.startMonth, ctx.parent.endMonth], value)
    ),
    qualityConsistencyThreshold: Yup.string()
      .required('is required')
      .typeError('has to be a number')
      .test('Numeric only', 'has to be a number', (value) => numericOnlyCheck(value))
      .test('Within range', 'has to be between 0 and 1', (value) => rangeCheck(value, 0, 1)),
    coverageQualityThreshold: Yup.string()
      .required('is required')
      .typeError('has to be a number')
      .test('Numeric only', 'has to be a number', (value) => numericOnlyCheck(value))
      .test('Within range', 'has to be between 0 and 1', (value) => rangeCheck(value, 0, 1)),
    trafficTailSitesNonConsidered: Yup.string()
      .required('is required')
      .typeError('has to be a number')
      .test('Numeric only', 'has to be a number', (value) => numericOnlyCheck(value))
      .test('Within range', 'has to be between 0 and 1', (value) => rangeCheck(value, 0, 1)),
    minimumMicrosegmentSize: Yup.string()
      .required('is required')
      .typeError('has to be a number')
      .test('Numeric only', 'has to be a whole number (no decimal)', (value) => numericOnlyCheck(value, false))
      .test('More than 0', 'has to be more than 0', (value) => rangeCheck(value, 0, null)),
    percentageOfClients: Yup.string()
      .required('is required')
      .typeError('has to be a number')
      .test('Numeric only', 'has to be a number', (value) => numericOnlyCheck(value))
      .test('Within range', 'has to be between 0 and 1', (value) => rangeCheck(value, 0, 1)),
  });

  const isDisabled = () => {
    // return disabled || (phase && phase?.status === PHASE_STATUSES.done);
    return disabled;
  };

  return (
    <div title="DatesAndParameters" className="mt-2" id="datesAndParameters">
      <SubPhaseHeader title="Dates of data period" index={1} />
      <div className="dapi-body">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          validateOnChange
          initialTouched={false}
          onSubmit={(values) => {
            const datesData = {
              ...phase,
              status: PHASE_STATUSES.valid,
              data: JSON.stringify({
                fields: [
                  {
                    ...sectionData,
                    status: SUBPHASE_STATUSES.valid,
                    value: values,
                  },
                ],
              }),
            };
            dispatch(updateScenarioInput(phase?.id, phase?.scenario_id, datesData));
            dispatch(getScenario(phase?.scenario_id));
          }}
        >
          {({ errors, setFieldTouched, touched, values, handleChange, submitForm, isValid }) => (
            <Form>
              <div style={{ display: 'flex' }}>
                <div
                  className={`date-input dapi-input ${clsx({
                    error: touched.startMonth && errors.startMonth,
                  })}`}
                >
                  <label htmlFor="startMonth" className="input-label">
                    Start Month
                  </label>
                  <Field
                    name="startMonth"
                    type="text"
                    placeholder="YYYY/MM"
                    className={disabled ? 'scenario-disable-cursor' : ''}
                    validate={(value) => validateDate(value, values)}
                    disabled={isDisabled()}
                    onChange={(e) => {
                      setFieldTouched(e.target.name);
                      handleChange(e);
                      monthDispatch({ type: 'startMonth', value: e.target.value });
                    }}
                  />
                  {touched.startMonth && errors.startMonth && (
                    <div className="message">
                      <WText typo="caption">{errors.startMonth}</WText>
                    </div>
                  )}
                </div>
                <div
                  className={`date-input dapi-input ${clsx({
                    error: touched.endMonth && errors.endMonth,
                  })}`}
                >
                  <label htmlFor="endMonth" className="input-label">
                    End Month
                  </label>
                  <Field
                    name="endMonth"
                    type="text"
                    placeholder="YYYY/MM"
                    className={disabled ? 'scenario-disable-cursor' : ''}
                    validate={validateDate}
                    disabled={isDisabled()}
                    onChange={(e) => {
                      setFieldTouched(e.target.name);
                      handleChange(e);
                      monthDispatch({ type: 'endMonth', value: e.target.value });
                    }}
                  />
                  {touched.endMonth && errors.endMonth && (
                    <div className="message">
                      <WText typo="caption">{errors.endMonth}</WText>
                    </div>
                  )}
                </div>
                <div
                  className={`date-input dapi-input ${clsx({
                    error: touched.targetMonth && errors.targetMonth,
                  })}`}
                >
                  <label htmlFor="targetMonth" className="input-label">
                    Target Month
                  </label>
                  {/* <TargetMonthField name="targetMonth" /> */}
                  <Field
                    name="targetMonth"
                    type="text"
                    placeholder="YYYY/MM"
                    className={disabled ? 'scenario-disable-cursor' : ''}
                    validate={validateDate}
                    disabled={isDisabled()}
                    onChange={(e) => {
                      setFieldTouched(e.target.name);
                      handleChange(e);
                      monthDispatch({ type: 'targetMonth', value: e.target.value });
                    }}
                  />
                  {touched.targetMonth && errors.targetMonth && (
                    <div className="message">
                      <WText typo="caption">{errors.targetMonth}</WText>
                    </div>
                  )}
                </div>
              </div>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {/* <div
                  className={`date-input dapi-input ${clsx({
                    error: touched.qualityConsistencyThreshold && errors.qualityConsistencyThreshold,
                  })}`}
                >
                  <label htmlFor="qualityConsistencyThreshold" className="input-label">
                    Quality consistency threshold
                  </label>
                  <Field
                    name="qualityConsistencyThreshold"
                    type="text"
                    placeholder="0.0"
                    className={disabled ? 'scenario-disable-cursor' : ''}
                    disabled={isDisabled()}
                    onChange={(e) => {
                      setFieldTouched(e.target.name);
                      handleChange(e);
                    }}
                  />
                  {touched.qualityConsistencyThreshold && errors.qualityConsistencyThreshold && (
                    <div className="message">
                      <WText typo="caption">{errors.qualityConsistencyThreshold}</WText>
                    </div>
                  )}
                </div> */}
                {/* <div
                  className={`date-input dapi-input ${clsx({
                    error: touched.coverageQualityThreshold && errors.coverageQualityThreshold,
                  })}`}
                >
                  <label htmlFor="coverageQualityThreshold" className="input-label">
                    Coverage quality threshold
                  </label>
                  <Field
                    name="coverageQualityThreshold"
                    type="text"
                    placeholder="0.0"
                    className={disabled ? 'scenario-disable-cursor' : ''}
                    disabled={isDisabled()}
                    onChange={(e) => {
                      setFieldTouched(e.target.name);
                      handleChange(e);
                    }}
                  />
                  {touched.coverageQualityThreshold && errors.coverageQualityThreshold && (
                    <div className="message">
                      <WText typo="caption">{errors.coverageQualityThreshold}</WText>
                    </div>
                  )}
                </div> */}
                {/* <div
                  className={`date-input dapi-input ${clsx({
                    error: touched.trafficTailSitesNonConsidered && errors.trafficTailSitesNonConsidered,
                  })}`}
                >
                  <label htmlFor="trafficTailSitesNonConsidered" className="input-label">
                    Traffic tail sites not considered
                  </label>
                  <Field
                    name="trafficTailSitesNonConsidered"
                    type="text"
                    inputMode="numeric"
                    placeholder="0.0"
                    className={disabled ? 'scenario-disable-cursor' : ''}
                    disabled={isDisabled()}
                    onChange={(e) => {
                      setFieldTouched(e.target.name);
                      handleChange(e);
                    }}
                  />
                  {touched.trafficTailSitesNonConsidered && errors.trafficTailSitesNonConsidered && (
                    <div className="message">
                      <WText typo="caption">{errors.trafficTailSitesNonConsidered}</WText>
                    </div>
                  )}
                </div> */}
                {/* <div
                  className={`date-input dapi-input ${clsx({
                    error: touched.minimumMicrosegmentSize && errors.minimumMicrosegmentSize,
                  })}`}
                >
                  <label htmlFor="minimumMicrosegmentSize" className="input-label">
                    Minimum microsegment size
                  </label>
                  <Field
                    name="minimumMicrosegmentSize"
                    type="text"
                    placeholder="0"
                    className={disabled ? 'scenario-disable-cursor' : ''}
                    disabled={isDisabled()}
                    onChange={(e) => {
                      setFieldTouched(e.target.name);
                      handleChange(e);
                    }}
                  />
                  {touched.minimumMicrosegmentSize && errors.minimumMicrosegmentSize && (
                    <div className="message">
                      <WText typo="caption">{errors.minimumMicrosegmentSize}</WText>
                    </div>
                  )}
                </div> */}
                {/* <div
                  className={`date-input dapi-input ${clsx({
                    error: touched.percentageOfClients && errors.percentageOfClients,
                  })}`}
                >
                  <label htmlFor="percentageOfClients" className="input-label">
                    Customer sample
                  </label>
                  <Field
                    name="percentageOfClients"
                    type="text"
                    placeholder="0.0"
                    className={disabled ? 'scenario-disable-cursor' : ''}
                    disabled={isDisabled()}
                    onChange={(e) => {
                      setFieldTouched(e.target.name);
                      handleChange(e);
                    }}
                  />
                  {touched.percentageOfClients && errors.percentageOfClients && (
                    <div className="message">
                      <WText typo="caption">{errors.percentageOfClients}</WText>
                    </div>
                  )}
                </div> */}
                {/* <div
                  className={`date-input dapi-input ${clsx({
                    error: touched.kmeasnModelScenarioId && errors.kmeasnModelScenarioId,
                  })}`}
                >
                  <label htmlFor="kmeasnModelScenarioId" className="input-label">
                    Kmeans model scenario ID
                  </label>
                  <Field
                    name="kmeasnModelScenarioId"
                    type="text"
                    placeholder=""
                    className={disabled ? 'scenario-disable-cursor' : ''}
                    disabled={isDisabled()}
                    onChange={(e) => {
                      setFieldTouched(e.target.name);
                      handleChange(e);
                    }}
                  />
                  {touched.kmeasnModelScenarioId && errors.kmeasnModelScenarioId && (
                    <div className="message">
                      <WText typo="caption">{errors.kmeasnModelScenarioId}</WText>
                    </div>
                  )}
                </div> */}
              </div>
              <button
                ref={scenarioContext.phaseSubmitBtnref.phase1}
                type="button"
                className="scenarion-submit-button-hide"
                onClick={() => {
                  if (isEqual(initialValues, values) || !isEqual(initialValues, values)) {
                    updateScenarioContext(1, isValid, touched, scenarioContext);
                    if (isValid && (isEmpty(touched) || !isEmpty(touched))) submitForm();
                  }
                }}
              >
                &nbsp;
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

DatesAndParametersInput.propTypes = {
  phase: PropTypes.shape({
    id: PropTypes.number.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    file_id: PropTypes.number,
    scenario_id: PropTypes.number.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    phase_number: PropTypes.number.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    status: PropTypes.oneOf(Object.values(PHASE_STATUSES)).isRequired,
    // eslint-disable-next-line react/require-default-props
    data: PropTypes.shape({
      fields: PropTypes.arrayOf(
        PropTypes.shape({
          section: PropTypes.string,
          status: PropTypes.oneOf(Object.values(PHASE_STATUSES)),
          value: PropTypes.shape({
            coverageQualityThreshold: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            endMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            minimumMicrosegmentSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            percentageOfClients: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            qualityConsistencyThreshold: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            startMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            targetMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            trafficTailSitesNonConsidered: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            userName: PropTypes.string,
            userRole: PropTypes.string,
            kmeasnModelScenarioId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          }),
        })
      ),
    }).isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    errors: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    // eslint-disable-next-line react/no-unused-prop-types
    created_at: PropTypes.string,
    // eslint-disable-next-line react/no-unused-prop-types
    updated_at: PropTypes.string,
  }).isRequired,
  sectionData: PropTypes.shape({
    fields: PropTypes.arrayOf({
      section: PropTypes.string,
      status: PropTypes.oneOf(Object.values(PHASE_STATUSES)),
      value: PropTypes.shape({
        coverageQualityThreshold: PropTypes.oneOf([PropTypes.string, null]),
        endMonth: PropTypes.oneOf([PropTypes.string, null]),
        minimumMicrosegmentSize: PropTypes.oneOf([PropTypes.string, null]),
        percentageOfClients: PropTypes.oneOf([PropTypes.string, null]),
        qualityConsistencyThreshold: PropTypes.oneOf([PropTypes.string, null]),
        startMonth: PropTypes.oneOf([PropTypes.string, null]),
        targetMonth: PropTypes.oneOf([PropTypes.string, null]),
        trafficTailSitesNonConsidered: PropTypes.oneOf([PropTypes.string, null]),
        userName: PropTypes.string,
        userRole: PropTypes.string,
        kmeasnModelScenarioId: PropTypes.oneOf([PropTypes.string, null]),
      }),
    }),
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    email: PropTypes.string,
    photo_url: PropTypes.string,
    function: PropTypes.number,
    is_active: PropTypes.bool,
    is_staff: PropTypes.bool,
    is_superuser: PropTypes.bool,
    role: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
  monthDispatch: PropTypes.func.isRequired,
};

export default DatesAndParametersInput;
