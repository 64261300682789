import { useEffect, useState } from 'react';
import { Card } from 'primereact/card';
import BarChartComponent from '../../../../components/Chart/BarChartComponent';
import './Summary.scss';
import axios from '../../../../utils/axios';
import Filters from '../../../../components/Filters/Filters';
import { WSpinnerLarge } from '../../../../components/WLoader/WSpinner';
import { formatNumber, convertToTitleCase, areObjectsEqual } from '../../../../utils/utils';

// const data1 = [{ name: 'Page A', uv: 4000, pv: 2400, amt: 2400 }];

// const data = [
//   {
//     name: 'NewBuildSC_Outdoor/4G_2600-FDD/5G_3500-TDD',
//     value: 305,
//     capex: '$5,938,080',
//     category: 'New Build SC',
//   },
//   {
//     name: 'NewBuild_Near_Outdoor/5G_700-FDD/4G_8000-FDD/4G_900-FDD',
//     value: 66,
//     capex: '$5,295,572',
//     category: 'New Build Near',
//   },
//   {
//     name: 'NewBuild_Near_Outdoor/5G_700-FDD/4G_8000-FDD/4G_900-FDD',
//     value: 195,
//     capex: '$15,554,280',
//     category: 'New Build Near',
//   },
//   {
//     name: 'NewBuildSC_Outdoor/4G_2600-FDD/5G_3500-TDD',
//     value: 305,
//     capex: '$5,938,080',
//     category: 'New Build SC',
//   },
//   {
//     name: 'NewBuild_Near_Outdoor/5G_700-FDD/4G_8000-FDD/4G_900-FDD',
//     value: 66,
//     capex: '$5,295,572',
//     category: 'New Build Near',
//   },
//   {
//     name: 'NewBuild_Near_Outdoor/5G_700-FDD/4G_8000-FDD/4G_900-FDD',
//     value: 195,
//     capex: '$15,554,280',
//     category: 'New Tech Freq',
//   },
//   {
//     name: 'NewBuildSC_Outdoor/4G_2600-FDD/5G_3500-TDD',
//     value: 305,
//     capex: '$5,938,080',
//     category: 'New Build SC',
//   },
//   {
//     name: 'NewBuild_Near_Outdoor/5G_700-FDD/4G_8000-FDD/4G_900-FDD',
//     value: 66,
//     capex: '$5,295,572',
//     category: 'New Build Near',
//   },
//   {
//     name: 'NewBuild_Near_Outdoor/5G_700-FDD/4G_8000-FDD/4G_900-FDD',
//     value: 195,
//     capex: '$15,554,280',
//     category: 'New Tech Freq',
//   },
// ];

// const filterData = {
//   color: { type: 'select', values: ['Red', 'Green', 'Blue'] },
//   size: { type: 'select', values: ['Small', 'Medium', 'Large'] },
//   price: { type: 'range', values: [0, 100] }, // Range from 0 to 100
// };

const Summary = ({ scenarioID }) => {
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(true);

  const handleFilterChange = (key, type, selectedValues) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: {
        type,
        values: selectedValues,
      },
    }));
    console.log('filters', filters);
    console.log('filterData', filterData);
    // setFilterData((prevFilterData) => ({
    //   ...prevFilterData,
    //   [key]: {
    //     type,
    //     values: selectedValues,
    //   },
    // }));
  };

  useEffect(() => {
    setLoading(true);
    const url = '/api/charts/phase3-summary/';
    axios
      .post(url, filters, {
        headers: {
          scenario_id: `scenario_${scenarioID}`,
        },
      })
      .then((response) => {
        setData(response.data.data);
        setFilterData(response.data.filters);
        Object.keys(response.data.filters).forEach((key) => {
          const type = response.data.filters[key].type;
          const values = response.data.filters[key].values;
          handleFilterChange(key, type, values);
        });
        setLoading(false);
      });
  }, []);

  const applyFilters = () => {
    setLoading(true);
    const url = '/api/charts/phase3-summary/';
    const filtersBody = areObjectsEqual(filters, filterData) ? {} : filters;
    axios
      .post(url, filtersBody, {
        headers: {
          scenario_id: `scenario_${scenarioID}`,
        },
      })
      .then((response) => {
        setData(response.data.data);
        // setFilterData(response.data.filters);
        setLoading(false);
      });
  };

  return (
    <div className="container1">
      {/* {isOpen && <FiltersModal isOpen={isOpen} openHandler={openHandler} filterData={filterData} />} */}
      {loading ? (
        <div className="page-spinner">
          <WSpinnerLarge />
        </div>
      ) : (
        <>
          <div style={{ flex: 9, paddingTop: '15px' }}>
            <Filters
              data={filterData}
              filters={filters}
              handleFilterChange={handleFilterChange}
              applyFilters={applyFilters}
            />
          </div>
          <div style={{ flex: 1 }}>
            <div className="p-grid p-nogutter stats-container">
              <div className="p-col-12 p-md-6 cards">
                {Object.keys(data.stats).map((key) => (
                  <Card className="custom-card">
                    <div className="card-content">
                      <h2>{convertToTitleCase(key)}</h2>
                      <h3>{formatNumber(data.stats[key])}</h3>
                    </div>
                  </Card>
                ))}
              </div>
            </div>

            <BarChartComponent data={data} phase={3} />
          </div>
        </>
      )}
    </div>
  );
};

export default Summary;
